import axios from "../axios";

class ProposalProvider {
  route;
  constructor() {
    this.route = "api/Proposal";
  }

  async getContractualTerms(proposalUniqueId) {
    return await axios.get(`${this.route}/GetProposalContractualTerms`, {
      params: { proposalUniqueId }
    });
  }
  async updateParticularClauses(proposalId, particularClauses) {
    return await axios.post(`${this.route}/UpdateParticularClauses`, particularClauses, { params: { proposalId } })
  }
}

export default new ProposalProvider();