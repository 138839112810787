var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cosseguro" }, [
    _c("hr", { staticClass: "my-12 opacity-25" }),
    _c("h4", { staticClass: "mb-10" }, [_vm._v("Cosseguro")]),
    _c(
      "div",
      { staticClass: "mb-10 w-2/12" },
      [
        _vm._m(0),
        _c(
          "el-select",
          {
            staticClass: "cosseguro-mapfre-select",
            attrs: { placeholder: "Selecione" },
            on: { change: _vm.trocarTipoCosseguro },
            model: {
              value: _vm.tipoCosseguro,
              callback: function ($$v) {
                _vm.tipoCosseguro = $$v
              },
              expression: "tipoCosseguro",
            },
          },
          _vm._l(_vm.tiposCosseguro, function (cosseguro) {
            return _c("el-option", {
              key: cosseguro.Id,
              attrs: { label: cosseguro.Nome, value: cosseguro.Id },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm.tipoCosseguro === 1 || _vm.tipoCosseguro === 2
      ? _c(
          "div",
          { staticClass: "box-cosseguro" },
          [
            _vm._l(_vm.listaCosseguradoras, function (cosseguradora, idx) {
              return _c("div", { key: idx, staticClass: "px-8" }, [
                _vm._m(1, true),
                _c("div", { staticClass: "box-cosseguro-cosseguradora" }, [
                  _c("div", { staticClass: "vx-col w-11/12" }, [
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _vm._m(2, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["##.###.###/####-##"],
                                expression: "['##.###.###/####-##']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: { name: "cnpj", size: "large" },
                            on: {
                              blur: function ($event) {
                                return _vm.buscarCosseguradora($event, idx)
                              },
                            },
                            model: {
                              value: (cosseguradora || {}).CoInsurance.Person
                                .CpfCnpj,
                              callback: function ($$v) {
                                _vm.$set(
                                  (cosseguradora || {}).CoInsurance.Person,
                                  "CpfCnpj",
                                  $$v
                                )
                              },
                              expression:
                                "(cosseguradora || {}).CoInsurance.Person.CpfCnpj",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _vm._m(3, true),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { name: "name", size: "large" },
                            on: {
                              blur: function ($event) {
                                return _vm.enviarInformacoes()
                              },
                            },
                            model: {
                              value: (cosseguradora || {}).CoInsurance.Person
                                .Name,
                              callback: function ($$v) {
                                _vm.$set(
                                  (cosseguradora || {}).CoInsurance.Person,
                                  "Name",
                                  $$v
                                )
                              },
                              expression:
                                "(cosseguradora || {}).CoInsurance.Person.Name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _c("p", [
                            _c("b", [
                              _vm._v(
                                "Código da cosseguradora\n                  " +
                                  _vm._s(_vm.tipoCosseguro === 2 ? "líder" : "")
                              ),
                            ]),
                          ]),
                          _c("vs-input", {
                            staticClass: "w-full",
                            attrs: { name: "codeCoInsurance", size: "large" },
                            on: {
                              blur: function ($event) {
                                return _vm.enviarInformacoes()
                              },
                            },
                            model: {
                              value: (cosseguradora || {}).CodeCoInsurance,
                              callback: function ($$v) {
                                _vm.$set(
                                  cosseguradora || {},
                                  "CodeCoInsurance",
                                  $$v
                                )
                              },
                              expression:
                                "(cosseguradora || {}).CodeCoInsurance",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tipoCosseguro === 2
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(4, true),
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  name: "numberIdentity",
                                  size: "large",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.enviarInformacoes()
                                  },
                                },
                                model: {
                                  value: (cosseguradora || {}).NumberIdentity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      cosseguradora || {},
                                      "NumberIdentity",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "(cosseguradora || {}).NumberIdentity",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "vx-row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _vm._m(5, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.##", "##.##", "#.##"],
                                expression: "['###.##', '##.##', '#.##']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "percentParticipation",
                              size: "large",
                              value: _vm.formatPercent(
                                (cosseguradora || {}).PercentParticipation
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.updatePercent(
                                  $event,
                                  idx,
                                  "PercentParticipation"
                                )
                              },
                              blur: function ($event) {
                                return _vm.enviarInformacoes()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _vm._m(6, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.##", "##.##", "#.##"],
                                expression: "['###.##', '##.##', '#.##']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "percentBroker",
                              size: "large",
                              value: _vm.formatPercent(
                                (cosseguradora || {}).PercentBroker
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.updatePercent(
                                  $event,
                                  idx,
                                  "PercentBroker"
                                )
                              },
                              blur: function ($event) {
                                return _vm.enviarInformacoes()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "vx-col",
                          class: [
                            _vm.tipoCosseguro === 1 ? "w-4/12" : "w-3/12",
                          ],
                        },
                        [
                          _vm._m(7, true),
                          _c("vs-input", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: ["###.##", "##.##", "#.##"],
                                expression: "['###.##', '##.##', '#.##']",
                              },
                            ],
                            staticClass: "w-full",
                            attrs: {
                              name: "percentSpanding",
                              size: "large",
                              value: _vm.formatPercent(
                                (cosseguradora || {}).PercentSpanding
                              ),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.updatePercent(
                                  $event,
                                  idx,
                                  "PercentSpanding"
                                )
                              },
                              blur: function ($event) {
                                return _vm.enviarInformacoes()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.tipoCosseguro === 2
                        ? _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(8, true),
                              _c("datepicker", {
                                staticClass: "w-full",
                                attrs: {
                                  language: _vm.ptBR,
                                  format: "dd/MM/yyyy",
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.enviarInformacoes()
                                  },
                                },
                                model: {
                                  value: (cosseguradora || {}).IdentityDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      cosseguradora || {},
                                      "IdentityDate",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "(cosseguradora || {}).IdentityDate",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm.tipoCosseguro === 2
                      ? _c("div", { staticClass: "vx-row" }, [
                          _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(9, true),
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  name: "leadPolicyNumber",
                                  size: "large",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.enviarInformacoes()
                                  },
                                },
                                model: {
                                  value: (cosseguradora || {}).LeadPolicyNumber,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      cosseguradora || {},
                                      "LeadPolicyNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "(cosseguradora || {}).LeadPolicyNumber",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "vx-col w-3/12" },
                            [
                              _vm._m(10, true),
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  name: "numberEndorsement",
                                  size: "large",
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.enviarInformacoes()
                                  },
                                },
                                model: {
                                  value: (cosseguradora || {})
                                    .NumberEndorsement,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      cosseguradora || {},
                                      "NumberEndorsement",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "(cosseguradora || {}).NumberEndorsement",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.tipoCosseguro === 1 && idx !== 0
                    ? _c(
                        "div",
                        {
                          staticClass: "trash-icon-mapfre",
                          on: {
                            click: function ($event) {
                              return _vm.removerCosseguradora(idx)
                            },
                          },
                        },
                        [_c("i", { staticClass: "onpoint-trash icon-font" })]
                      )
                    : _vm._e(),
                ]),
              ])
            }),
            _vm.tipoCosseguro === 1
              ? _c("div", { staticClass: "footer-right" }, [
                  _c(
                    "div",
                    {
                      staticClass: "adicionar-cosseguradora",
                      on: { click: _vm.adcCosseguradora },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "adicionar-cosseguradora-text" },
                        [_vm._v("Adicionar cosseguradora")]
                      ),
                      _vm._m(11),
                    ]
                  ),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Tipo de cosseguro")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("b", [_vm._v("Cosseguradora")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("CNPJ")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Razão social")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Identificação (opcional)")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("% de participação")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("% corretor")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("% gastos")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Data da identificação (opcional)")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Número apólice líder")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Endosso")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "adicionar-cosseguradora-plus-button" }, [
      _c("i", { staticClass: "onpoint-plus icon-font" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }