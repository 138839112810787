var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.proposalObservations.length == 0
      ? _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full mt-2" },
            [
              _c(
                "vs-alert",
                { staticClass: "h-auto", attrs: { color: "warning" } },
                [_vm._v("\n        Nenhum follow up até o momento.\n      ")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "vx-row" },
      _vm._l(_vm.proposalObservations, function (mensagem, index) {
        return _c("vs-card", { key: index, staticClass: "p-8 pt-4 pb-4" }, [
          _c("div", { staticClass: "vx-row px-8 mb-5" }, [
            _c("div", { staticClass: "vx-col md:w-10/12 w-full mt-5" }, [
              _c("h6", { staticClass: "flex" }, [
                _c("span", { staticClass: "text-primary mr-2 mb-2" }, [
                  _vm._v(_vm._s(mensagem.CreateByPerson)),
                ]),
                _c("small", [
                  _vm._v(
                    "escreveu em\n              " +
                      _vm._s(
                        _vm._f("dateFormat")(
                          new Date(mensagem.CreateDate),
                          "DD/MM/YYYY"
                        )
                      )
                  ),
                ]),
              ]),
              _c("p", { staticClass: "pl-4" }, [
                _vm._v(_vm._s(mensagem.Observation)),
              ]),
            ]),
            _c("div", { staticClass: "vx-col md:w-2/12 w-full mt-5" }, [
              _c("span", { staticClass: "observation-origin" }, [
                _vm._v("Origem:"),
              ]),
              _c("span", { staticClass: "observation-origin-name" }, [
                _c("b", [_vm._v(_vm._s(mensagem.Source))]),
              ]),
            ]),
          ]),
        ])
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }