<template>
  <div>
    <vs-button type="border" @click="consultarEndossos()">
      Configuração de restituição
    </vs-button>
    <el-dialog
      :show-close="true"
      center
      class="configuracaoRestituicaoDialog"
      :visible.sync="configuracaoRestituicaoDialog"
      width="90%"
    >
      <template #title>
        <div class="boldText">Configuração de restituição</div>
        <el-divider class="mt-3 mb-0"></el-divider>
      </template>

      <div
        v-for="(propostaItem, i) in endossosDaProposta"
        :key="i"
        class="mr-0 ml-0"
      >
        <div class="configuracaoRestituicaoTable">
          <div>
            <p class="boldText">Nº endosso</p>
            <p class="endossoItemTable">
              {{ propostaItem.Endorsement.Number }}
            </p>
          </div>
          <div>
            <p class="boldText">Proposta do endosso</p>
            <p class="endossoItemTable">{{ propostaItem.Number }}</p>
          </div>
          <div>
            <p class="boldText">Emissão</p>
            <p class="endossoItemTable">
              {{ formatDate(propostaItem.Endorsement.CreateDate) }}
            </p>
          </div>
          <div>
            <p class="boldText">Início da vigência</p>
            <p class="endossoItemTable">
              {{ formatDate(propostaItem.StartDate) }}
            </p>
          </div>
          <div>
            <p class="boldText">Fim da vigência</p>
            <p class="endossoItemTable">
              {{ formatDate(propostaItem.EndDate) }}
            </p>
          </div>
          <div>
            <p class="boldText">Tipo</p>
            <p class="endossoItemTable">
              {{ propostaItem.Endorsement.EndorsementType }}
            </p>
          </div>
          <div>
            <p class="boldText">IS</p>
            <p class="endossoItemTable">
              R$ {{ propostaItem.InsuredAmountValue }}
            </p>
          </div>
          <div>
            <p class="boldText">Prêmio</p>
            <p class="endossoItemTable">
              R$ {{ propostaItem.InsurancePremium }}
            </p>
          </div>
          <div style="'position:relative;top:9px;'">
            <p class="boldText">
              Confirmar valor <i class="asteristicColor">*</i>
            </p>
            <el-input
              class="inputRestituitionDialog"
              placeholder="R$"
              max="10"  
              @input="formatterValueToEndossoDaProposta($event,i)"    
              :value="propostaItem.newInsurancePremiumFormated"          
            />           
           
            <div>
              <p  class="alertValueError" v-if="propostaItem.newInsurancePremium > propostaItem.InsurancePremium">
                O Valor deve ser menor que o prêmio
              </p>
            </div>
          </div>
        </div>       
        <el-divider v-if="i < endossosDaProposta.length - 1"></el-divider>
      </div>
      <el-divider class="mb-4"></el-divider>
      <div class="flex justify-end mt-5 mb-5">
        <div>
          <p class="boldText">
            Valor total
          </p>
          <el-input
            class="inputRestituitionDialog"
            placeholder="R$"
            disabled
            max="10" 
            :value="totalValue"
          /> 
        </div>
      </div>
      <div style="display: flex; justify-content: end">
        <button
          class="configurarRestituicaoButtonCancel"
          @click="configuracaoRestituicaoDialog = false"
        >
          Cancelar
        </button>       
        <button  
          :disabled="valueRestituitionError"
          :class="valueRestituitionError ? 'configurarRestituicaoButtonSaveDisabled' : 'configurarRestituicaoButtonSave'"
          @click="createRestituitionEndorsement(endossosDaProposta)"
        >
          Salvar alterações
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import axiosInstance from "@/axios";

export default {
  name: "configuracao-restituicao",
  props: {
    proposta: {
      required: true,      
    },
  },
  data() {
    return {
      endossosDaProposta:[],
      configuracaoRestituicaoDialog:false,
      valueIsbiggerThanInsurancePremium:false,
      newInsurancePremiumFormated:null,
      totalValue:0      
    };
  },
  computed:{
    valueRestituitionError(){    
    let valueIsbiggerThanInsurancePremium = false
    const valueRestituition = this.endossosDaProposta.filter(proposta => proposta.newInsurancePremium)
    valueRestituition.forEach(element => {
      element.newInsurancePremium > element.InsurancePremium ? valueIsbiggerThanInsurancePremium=true: null
    });
    return valueIsbiggerThanInsurancePremium
    }
  },
  methods: {
    ...mapActions("proposta-module", [ "getEndossoByPolicyUniqueId"]),

    async consultarEndossos() {      
      await this.$onpoint.loading(async () => {
        await this.getEndossoByPolicyUniqueId(
          this.proposta.Policy.UniqueId
        ).then((data) => (this.endossosDaProposta = data));
      });
      this.configuracaoRestituicaoDialog = true;      
    },
    formatDate(value) {
      if (!value) return '';
      return moment(value).format('DD/MM/YYYY');
    },
    createRestituitionEndorsementPayload(propostas){      
      let payload = []
      payload =  propostas.filter(proposta => proposta.newInsurancePremium)       
      return payload
    },
    async createRestituitionEndorsement(propostas){ 
      
      const newRestituitionEndorsements = this.createRestituitionEndorsementPayload(propostas)
      
      let restituitionProposal = []
  
      restituitionProposal = newRestituitionEndorsements.map((element, index) =>({    
        ComplementaryDeadlineDays:this.proposta.ComplementaryDeadlineDays || null,
        ComplementaryEndDate:this.proposta.ComplementaryEndDate || null,
        ComplementaryInsuredAmountValue:this.proposta.ComplementaryInsuredAmountValue || null,
        CompleteObject:this.proposta.CompleteObject || null,
        ContractualTerms:null,
        DeadlineDays:this.proposta.DeadlineDays || null,
        Documents:this.proposta.Documents|| null,
        EndDate:this.proposta.EndDate || null,
        EndorsementObject:this.proposta.EndorsementObject || null,
        EndorsementTypeId:this.proposta.EndorsementTypeId || null,
        Insured:this.proposta.Insured || null,
        InsuredAmountValue:this.proposta.InsuredAmountValue || null,
        IsCompleteChangeObject:this.proposta.IsCompleteChangeObject || null,
        JustificationIsurancePremiumReview:this.proposta.JustificationIsurancePremiumReview || null,
        PolicyHolder:this.proposta.PolicyHolder || null,
        PolicyUniqueId:this.proposta.Policy.UniqueId || null,
        ReasonEndorsementRequestId:this.proposta.ReasonEndorsementRequestId || null,
        RequestInsurancePremiumReview:this.proposta.RequestInsurancePremiumReview || null,
        RestoreInsurancePremium:this.proposta.RestoreInsurancePremium || null,
        StartDate:this.proposta.StartDate || null,  
        InsurancePremium: element.newInsurancePremium || null,
        RelatedProposalId:element.Endorsement.ProposalId || null,       
      }))
  
      await this.$onpoint.loading(async() => {        
        await axiosInstance
        .post('/api/endorsement/CreateApproveEndorsementBulk', { 
          CreateEndorsementOptions:{
            PolicyUniqueId:this.proposta.Policy.UniqueId,
            EndorsementProposalUniqueId:this.proposta.UniqueId,              
          },
          CreateEndorsementRequestList:restituitionProposal           
        })
        .then((data)=>{
          return this.$router.go()       
        })         
        .catch(ex => {
          this.$onpoint.errorModal(ex.response.data.Errors);
        });
      })
    },
    formatterValueToEndossoDaProposta(event,index){  
      if (!event) return

      let valueFormatted = event      
      valueFormatted =  valueFormatted.replace(/\D/g, "");
      valueFormatted = (parseInt(valueFormatted) / 100).toFixed(2);
     
      if(index ===0 || index){
        this.endossosDaProposta[index].newInsurancePremiumFormated = valueFormatted.replace('.',',')
        this.endossosDaProposta[index].newInsurancePremium = valueFormatted
      }   
      this.calculeTotalValue()
    },
    calculeTotalValue(){
      this.totalValue = 0

      this.endossosDaProposta.forEach((element)=>{
        if(element.newInsurancePremium){
           this.totalValue = Number(this.totalValue) + Number(element.newInsurancePremium)    
        }
      })
      
      
      this.totalValue = this.totalValue.toFixed(2).toString().replace('.',',')
    }
  },
};
</script>

<style>
.configuracaoRestituicaoTable{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;  
}

 .configurarRestituicaoButtonCancel{
  border: solid 2px;
  border-color: #d1d5db;
  margin-right: 30px;
  height: 35px;  
  margin-bottom:20px ;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  background-color: white;  
}
.configurarRestituicaoButtonSave{
  border: none;
  height: 35px;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #0e65a5;
  border: solid 2px;
  border-color: #0e65a5;
  color: white;
}  
.configuracaoRestituicaoDialog div.el-dialog{
  border-radius: 20px !important;
}
.boldText{
font-weight: bold;
}
.endossoitemTable{
  position:relative;
  top:8px;
}
.asteristicColor{
  color:#b4173a;
}
.inputRestituitionDialog{
  max-width:150px;
}
.alertValueError{
  padding: 5px;
  font-size: 10px;
  max-width: 160px;
  color:red;
}
.configurarRestituicaoButtonSaveDisabled{
  border: none;
  height: 35px;
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #9ac9eb;
  border: solid 2px;
  border-color: #9ac9eb;
  color: white;
}
</style>