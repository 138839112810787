<template>
  <section>
    <div class="vx-row" v-if="proposalObservations.length == 0">
      <div class="vx-col w-full mt-2">
        <vs-alert color="warning" class="h-auto">
          Nenhum follow up até o momento.
        </vs-alert>
      </div>
    </div>
    <div class="vx-row">
      <vs-card
        class="p-8 pt-4 pb-4"
        v-for="(mensagem, index) in proposalObservations"
        :key="index"
      >
        <div class="vx-row px-8 mb-5">
          <div class="vx-col md:w-10/12 w-full mt-5">
            <h6 class="flex">
              <span class="text-primary mr-2 mb-2">{{
                mensagem.CreateByPerson
              }}</span>
              <small
                >escreveu em
                {{
                  new Date(mensagem.CreateDate) | dateFormat("DD/MM/YYYY")
                }}</small
              >
            </h6>
            <p class="pl-4">{{ mensagem.Observation }}</p>
          </div>
          <div class="vx-col md:w-2/12 w-full mt-5">
            <span class="observation-origin">Origem:</span>
            <span class="observation-origin-name"
              ><b>{{ mensagem.Source }}</b></span
            >
          </div>
        </div>
      </vs-card>
    </div>
  </section>
</template>
<script>
export default {
  name: "follow-up",
  components: {},
  data() {
    return {};
  },
  props: ["proposalObservations"],
  watch: {},
  methods: {}
};
</script>
<style lang="scss">
.observation-origin {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #50555a;
}
.observation-origin-name {
  background-color: #0f6eb429;
  border-radius: 5em;
  padding: 10px;
  margin: 15px;
  b {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.05px;
    color: #0f6eb4;
  }
}
small {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.05px;
  color: #8e8e8e;
}
</style>
