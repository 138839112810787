var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "proposal-documents" },
    [
      _c("h4", { staticClass: "mt-10" }, [_vm._v("Documentação do corretor")]),
      _c("div", { staticClass: "vx-row" }, [
        _c(
          "div",
          { staticClass: "vx-col pt-5 text-right w-full mt-2" },
          [
            _c(
              "vs-button",
              {
                attrs: { color: "primary", disabled: _vm.uploading },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openDialogUpload()
                  },
                },
              },
              [
                _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                  _vm._v("\n          Upload de documento\n        "),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _c(
        "vs-table",
        {
          staticClass: "mt-10 table-available",
          attrs: {
            data: _vm.validDocuments,
            noDataText: "Nenhum arquivo foi cadastrado",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("\n        Nome\n      ")]),
              _c("vs-th", [_vm._v("\n        Origem\n      ")]),
              _c("vs-th", [_vm._v("\n        Data de inserção\n      ")]),
              _c("vs-th", [_vm._v("\n        Ações\n      ")]),
            ],
            1
          ),
          _vm._l(_vm.validDocuments, function (document, indextr) {
            return _c(
              "vs-tr",
              { key: indextr, attrs: { data: document } },
              [
                _c("vs-td", [_vm._v(_vm._s(_vm.validDocuments[indextr].Name))]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      (_vm.validDocuments[indextr].CreateByPerson || {}).Name
                    )
                  ),
                ]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.validDocuments[indextr].CreateDate,
                        "DD/MM/YYYY HH:mm"
                      )
                    )
                  ),
                ]),
                _c(
                  "vs-td",
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          color: "dark",
                          type: "flat",
                          title: "Download do documento",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.downloadDocument(
                              _vm.validDocuments[indextr].UniqueId
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "onpoint-download-simple icon-font",
                        }),
                      ]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          color: "dark",
                          type: "flat",
                          title: "Editar nome do documento",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openDialogEditDocument(
                              _vm.validDocuments[indextr].UniqueId
                            )
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "onpoint-pencil-simple-line icon-font",
                        }),
                      ]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "p-0",
                        attrs: {
                          color: "dark",
                          type: "flat",
                          title: "Excluir documento",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.openDialogDeleteDocument(
                              _vm.validDocuments[indextr].UniqueId,
                              _vm.validDocuments[indextr].Name
                            )
                          },
                        },
                      },
                      [_c("i", { staticClass: "onpoint-trash icon-font" })]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "download-all",
          on: {
            click: function ($event) {
              return _vm.downloadAllDocuments()
            },
          },
        },
        [
          _c("span", [_vm._v("Baixar todos")]),
          _c("i", { staticClass: "onpoint-download icon-font" }),
        ]
      ),
      _c("h4", [_vm._v("Documentos excluidos")]),
      _c(
        "vs-table",
        {
          staticClass: "mt-10 table-trash",
          attrs: {
            data: _vm.trashDocuments,
            noDataText: "Nenhum arquivo foi excluído",
          },
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [_vm._v("\n        Nome\n      ")]),
              _c("vs-th", [_vm._v("\n        Origem\n      ")]),
              _c("vs-th", [_vm._v("\n        Data de inserção\n      ")]),
              _c("vs-th", [_vm._v("\n        Excluido por\n      ")]),
              _c("vs-th", [_vm._v("\n        Data da exclusão\n      ")]),
              _c("vs-th", [_vm._v("\n        Ações\n      ")]),
            ],
            1
          ),
          _vm._l(_vm.trashDocuments, function (document, indextr) {
            return _c(
              "vs-tr",
              { key: indextr, attrs: { data: document } },
              [
                _c("vs-td", [_vm._v(_vm._s(_vm.trashDocuments[indextr].Name))]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      (_vm.trashDocuments[indextr].CreateByPerson || {}).Name
                    )
                  ),
                ]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm.trashDocuments[indextr].CreateDate
                        ? _vm.$utils.dateToDDMMYYYY(
                            _vm.trashDocuments[indextr].CreateDate
                          )
                        : "-"
                    )
                  ),
                ]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      (_vm.trashDocuments[indextr].UpdateByPerson || {}).Name
                    )
                  ),
                ]),
                _c("vs-td", [
                  _vm._v(
                    _vm._s(
                      _vm._f("moment")(
                        _vm.trashDocuments[indextr].CreateDate,
                        "DD/MM/YYYY HH:mm"
                      )
                    )
                  ),
                ]),
                _c("vs-td", [
                  _c(
                    "p",
                    {
                      staticClass: "restore",
                      on: {
                        click: function ($event) {
                          return _vm.restoreDocument(
                            _vm.trashDocuments[indextr].UniqueId
                          )
                        },
                      },
                    },
                    [_vm._v("\n            Restaurar\n          ")]
                  ),
                ]),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-upload-document z-index-modal",
          attrs: {
            title: "Upload dedocumento",
            active: _vm.openDialogAddDocumente,
          },
          on: {
            "update:active": function ($event) {
              _vm.openDialogAddDocumente = $event
            },
          },
        },
        [
          _vm.files.name
            ? _c("p", [
                _c("b", [_vm._v(" Arquivo Selecionado:")]),
                _vm._v(" " + _vm._s(_vm.files.name)),
              ])
            : _vm._e(),
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c(
              "div",
              { staticClass: "upload-container" },
              [
                _c("div", { staticClass: "drag-drop-file" }, [
                  _c(
                    "label",
                    {
                      staticClass: "drag-drop-file-text-content",
                      attrs: { for: "upload" },
                    },
                    [
                      _c("i", {
                        staticClass: "onpoint-file icon-font",
                        staticStyle: { "font-size": "18px" },
                      }),
                      _c("p", { staticClass: "text" }, [
                        _vm._v("Selecionar Arquivo"),
                      ]),
                    ]
                  ),
                  _c("input", {
                    attrs: { id: "upload", type: "file", name: "avatar" },
                    on: {
                      change: function ($event) {
                        return _vm.setValue($event.target.files[0])
                      },
                    },
                  }),
                ]),
                _c("az-upload", {
                  staticStyle: { display: "none" },
                  attrs: {
                    singleUpload: true,
                    controller: _vm.uploadDocumentController,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("p", { staticClass: "flex font-semibold" }, [
                  _vm._v("Nomear documento:"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large" },
                  model: {
                    value: _vm.documentName,
                    callback: function ($$v) {
                      _vm.documentName = $$v
                    },
                    expression: "documentName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row flex justify-end" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", disabled: _vm.uploading },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.uploadDocument(_vm.documentName)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Enviar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-edit-documents z-index-modal",
          attrs: { title: "Editar nome", active: _vm.openEditDocument },
          on: {
            "update:active": function ($event) {
              _vm.openEditDocument = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c("p", { staticClass: "flex font-semibold" }, [
                  _vm._v("Informe o novo nome"),
                ]),
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { size: "large" },
                  model: {
                    value: _vm.newDocumentName,
                    callback: function ($$v) {
                      _vm.newDocumentName = $$v
                    },
                    expression: "newDocumentName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row flex justify-end" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.editDocument()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup-delete-documents z-index-modal",
          attrs: { title: "Deletar arquivo", active: _vm.openDeleteDocument },
          on: {
            "update:active": function ($event) {
              _vm.openDeleteDocument = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", [
              _c("h4", [_vm._v("Confirma a ação de deleter o documento?")]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c("div", { staticClass: "document-name" }, [
              _vm._v(_vm._s(_vm.documentName)),
            ]),
          ]),
          _c("div", { staticClass: "vx-row flex justify-center" }, [
            _c(
              "div",
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-10",
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        _vm.openDeleteDocument = false
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Voltar\n          "),
                    ]),
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { color: "dark" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.deleteDocument(_vm.RequestDocumentUniqueId)
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                      _vm._v("\n            Confirmar\n          "),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }