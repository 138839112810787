var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "resseguro" }, [
    _c("hr", { staticClass: "my-12 opacity-25" }),
    _c("h4", { staticClass: "mb-10" }, [_vm._v("Resseguro")]),
    _c(
      "div",
      { staticClass: "mb-10 w-2/12" },
      [
        _vm._m(0),
        _c(
          "el-select",
          {
            staticClass: "resseguro-mapfre-select",
            attrs: { placeholder: "Selecione" },
            on: { change: _vm.trocarTipoResseguro },
            model: {
              value: _vm.tipoResseguro,
              callback: function ($$v) {
                _vm.tipoResseguro = $$v
              },
              expression: "tipoResseguro",
            },
          },
          _vm._l(_vm.tiposResseguros, function (resseguro) {
            return _c("el-option", {
              key: resseguro.Id,
              attrs: { label: resseguro.Nome, value: resseguro.Id },
            })
          }),
          1
        ),
      ],
      1
    ),
    _vm.tipoResseguro !== 1
      ? _c(
          "div",
          { staticClass: "box-resseguro" },
          [
            _vm._l(_vm.listaResseguradoras, function (resseguradora, index) {
              return _c("div", { key: index, staticClass: "px-8" }, [
                _vm._m(1, true),
                _c(
                  "div",
                  {
                    staticClass: "vx-row mb-0 mt-0 box-resseguro-resseguradora",
                  },
                  [
                    _c("div", { staticClass: "vx-col w-11/12" }, [
                      _c("div", { staticClass: "vx-row" }, [
                        _c(
                          "div",
                          { staticClass: "vx-col w-3/12" },
                          [
                            _vm._m(2, true),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["##.###.###/####-##"],
                                  expression: "['##.###.###/####-##']",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { name: "cnpj", size: "large" },
                              on: {
                                blur: function ($event) {
                                  return _vm.buscarResseguradora($event, index)
                                },
                              },
                              model: {
                                value: (resseguradora || {}).Reinsurer.Person
                                  .CpfCnpj,
                                callback: function ($$v) {
                                  _vm.$set(
                                    (resseguradora || {}).Reinsurer.Person,
                                    "CpfCnpj",
                                    $$v
                                  )
                                },
                                expression:
                                  "(resseguradora || {}).Reinsurer.Person.CpfCnpj",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-3/12" },
                          [
                            _vm._m(3, true),
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { name: "nome", size: "large" },
                              on: {
                                blur: function ($event) {
                                  return _vm.enviarInformacoes()
                                },
                              },
                              model: {
                                value: (resseguradora || {}).Reinsurer.Person
                                  .Name,
                                callback: function ($$v) {
                                  _vm.$set(
                                    (resseguradora || {}).Reinsurer.Person,
                                    "Name",
                                    $$v
                                  )
                                },
                                expression:
                                  "(resseguradora || {}).Reinsurer.Person.Name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-2/12" },
                          [
                            _vm._m(4, true),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["###.##", "##.##", "#.##"],
                                  expression: "['###.##', '##.##', '#.##']",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: {
                                name: "participacaoContrato",
                                size: "large",
                                value: _vm.formatPercent(
                                  (resseguradora || {}).Percent
                                ),
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.updatePercent(
                                    $event,
                                    index,
                                    "Percent"
                                  )
                                },
                                blur: function ($event) {
                                  return _vm.enviarInformacoes()
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-2/12" },
                          [
                            _vm._m(5, true),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["#########"],
                                  expression: "['#########']",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { name: "numeroSlip", size: "large" },
                              on: {
                                blur: function ($event) {
                                  return _vm.enviarInformacoes()
                                },
                              },
                              model: {
                                value: (resseguradora || {}).NumberSlip,
                                callback: function ($$v) {
                                  _vm.$set(
                                    resseguradora || {},
                                    "NumberSlip",
                                    $$v
                                  )
                                },
                                expression: "(resseguradora || {}).NumberSlip",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "vx-col w-2/12" },
                          [
                            _vm._m(6, true),
                            _c("vs-input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: ["####"],
                                  expression: "['####']",
                                },
                              ],
                              staticClass: "w-full",
                              attrs: { name: "anoContrato", size: "large" },
                              on: {
                                blur: function ($event) {
                                  return _vm.enviarInformacoes()
                                },
                              },
                              model: {
                                value: (resseguradora || {}).ContractYear,
                                callback: function ($$v) {
                                  _vm.$set(
                                    resseguradora || {},
                                    "ContractYear",
                                    $$v
                                  )
                                },
                                expression:
                                  "(resseguradora || {}).ContractYear",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    index !== 0
                      ? _c(
                          "div",
                          {
                            staticClass: "trash-icon-mapfre",
                            on: {
                              click: function ($event) {
                                return _vm.removerResseguradora(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "onpoint-trash icon-font" })]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            _c("div", { staticClass: "footer-right" }, [
              _c(
                "div",
                {
                  staticClass: "adicionar-resseguradora",
                  on: { click: _vm.adcResseguradora },
                },
                [
                  _c("span", { staticClass: "adicionar-resseguradora-text" }, [
                    _vm._v("Adicionar resseguradora"),
                  ]),
                  _vm._m(7),
                ]
              ),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Tipo de resseguro")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mb-1" }, [
      _c("b", [_vm._v("Resseguradora")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("CNPJ")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Razão social")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("% participação do contrato")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Número slip")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_c("b", [_vm._v("Ano do contrato")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "adicionar-resseguradora-plus-button" }, [
      _c("i", { staticClass: "onpoint-plus icon-font" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }