<template>
  <div class="cosseguro">
    <hr class="my-12 opacity-25" />
    <h4 class="mb-10">Cosseguro</h4>

    <div class="mb-10 w-2/12">
      <p>
        <b>Tipo de cosseguro</b>
      </p>

      <el-select
        v-model="tipoCosseguro"
        placeholder="Selecione"
        class="cosseguro-mapfre-select"
        @change="trocarTipoCosseguro"
      >
        <el-option
          v-for="cosseguro in tiposCosseguro"
          :key="cosseguro.Id"
          :label="cosseguro.Nome"
          :value="cosseguro.Id"
        >
        </el-option>
      </el-select>
    </div>

    <div
      class="box-cosseguro"
      v-if="tipoCosseguro === 1 || tipoCosseguro === 2"
    >
      <div
        class="px-8"
        v-for="(cosseguradora, idx) in listaCosseguradoras"
        :key="idx"
      >
        <p class="mb-1">
          <b>Cosseguradora</b>
        </p>

        <div class="box-cosseguro-cosseguradora">
          <div class="vx-col w-11/12">
            <div class="vx-row">
              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b>CNPJ</b>
                </p>
                <vs-input
                  v-mask="['##.###.###/####-##']"
                  class="w-full"
                  name="cnpj"
                  size="large"
                  v-model="(cosseguradora || {}).CoInsurance.Person.CpfCnpj"
                  @blur="buscarCosseguradora($event, idx)"
                />
              </div>

              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b>Razão social</b>
                </p>
                <vs-input
                  class="w-full"
                  name="name"
                  size="large"
                  v-model="(cosseguradora || {}).CoInsurance.Person.Name"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b
                    >Código da cosseguradora
                    {{ tipoCosseguro === 2 ? "líder" : "" }}</b
                  >
                </p>
                <vs-input
                  class="w-full"
                  name="codeCoInsurance"
                  size="large"
                  v-model="(cosseguradora || {}).CodeCoInsurance"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div class="vx-col w-3/12" v-if="tipoCosseguro === 2">
                <p>
                  <b>Identificação (opcional)</b>
                </p>
                <vs-input
                  class="w-full"
                  name="numberIdentity"
                  size="large"
                  v-model="(cosseguradora || {}).NumberIdentity"
                  @blur="enviarInformacoes()"
                />
              </div>
            </div>

            <div class="vx-row">
              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b>% de participação</b>
                </p>
                <vs-input
                  v-mask="['###.##', '##.##', '#.##']"
                  class="w-full"
                  name="percentParticipation"
                  size="large"
                  :value="
                    formatPercent((cosseguradora || {}).PercentParticipation)
                  "
                  @input="updatePercent($event, idx, 'PercentParticipation')"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b>% corretor</b>
                </p>
                <vs-input
                  v-mask="['###.##', '##.##', '#.##']"
                  class="w-full"
                  name="percentBroker"
                  size="large"
                  :value="formatPercent((cosseguradora || {}).PercentBroker)"
                  @input="updatePercent($event, idx, 'PercentBroker')"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div
                class="vx-col"
                :class="[tipoCosseguro === 1 ? 'w-4/12' : 'w-3/12']"
              >
                <p>
                  <b>% gastos</b>
                </p>
                <vs-input
                  v-mask="['###.##', '##.##', '#.##']"
                  class="w-full"
                  name="percentSpanding"
                  size="large"
                  :value="formatPercent((cosseguradora || {}).PercentSpanding)"
                  @input="updatePercent($event, idx, 'PercentSpanding')"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div class="vx-col w-3/12" v-if="tipoCosseguro === 2">
                <p>
                  <b>Data da identificação (opcional)</b>
                </p>
                <datepicker
                  :language="ptBR"
                  class="w-full"
                  format="dd/MM/yyyy"
                  v-model="(cosseguradora || {}).IdentityDate" 
                  @input="enviarInformacoes()"
                />
              </div>
            </div>

            <div class="vx-row" v-if="tipoCosseguro === 2">
              <div class="vx-col w-3/12">
                <p>
                  <b>Número apólice líder</b>
                </p>
                <vs-input
                  class="w-full"
                  name="leadPolicyNumber"
                  size="large"
                  v-model="(cosseguradora || {}).LeadPolicyNumber"
                  @blur="enviarInformacoes()"
                />
              </div>

              <div class="vx-col w-3/12">
                <p>
                  <b>Endosso</b>
                </p>
                <vs-input
                  class="w-full"
                  name="numberEndorsement"
                  size="large"
                  v-model="(cosseguradora || {}).NumberEndorsement"
                  @blur="enviarInformacoes()"
                />
              </div>
            </div>
          </div>

          <div
            v-if="tipoCosseguro === 1 && idx !== 0"
            class="trash-icon-mapfre"
            @click="removerCosseguradora(idx)"
          >
            <i class="onpoint-trash icon-font"></i>
          </div>
        </div>
      </div>
      <div class="footer-right" v-if="tipoCosseguro === 1">
        <div class="adicionar-cosseguradora" @click="adcCosseguradora">
          <span class="adicionar-cosseguradora-text"
            >Adicionar cosseguradora</span
          >
          <div class="adicionar-cosseguradora-plus-button">
            <i class="onpoint-plus icon-font"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import * as Utils from "@/utils/utils";
import mapfreProvider from "@/providers/mapfre-provider.js";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";

export default {
  name: "cosseguro-mapfre",
  components: {
    Datepicker
  },
  directives: { mask },
  data() {
    return {
      tiposCosseguro: [
        {
          Nome: "0 - Isento",
          Id: 0
        },
        {
          Nome: "1 - Cedido",
          Id: 1
        },
        {
          Nome: "2 - Aceito",
          Id: 2
        }
      ],
      ptBR,
      tipoCosseguro: null,
      listaCosseguradoras: [],
      CoInsurancers: [],
      cosseguroMock: {
        Id: null,
        CompanyId: null,
        CoInsuranceType: null,
        CoInsuranceId: null,
        ProposalId: null,
        UniqueId: null,
        CoInsurance: {
          Id: null,
          PersonId: null,
          Person: {
            CpfCnpj: null,
            Name: null
          }
        },
        CodeCoInsurance: null,
        NumberIdentity: null,
        PercentBroker: null,
        PercentParticipation: null,
        PercentSpanding: null,
        IdentityDate: null,
        LeadPolicyNumber: null,
        NumberEndorsement: null
      }
    };
  },
  watch: {
    proposta() {
      this.init();
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta"]),
    ...mapGetters("auth", ["userInfo"])
  },
  methods: {
    formatPercent(value) {
      if (typeof value === "number") {
        let percent = Math.min(Math.max(value, 0), 100);
        return percent.toFixed(2);
      }
      return "";
    },

    updatePercent(event, index, field) {
      const value = event || "";
      let percent = parseFloat(value);

      if (!isNaN(percent)) {
        percent = Math.min(Math.max(percent, 0), 100);
        this.listaCosseguradoras[index][field] = percent;
      } else {
        this.listaCosseguradoras[index][field] = 0;
      }
    },

    resetCosseguradoraMock() {
      Utils.clearObjectAttributes(this.cosseguroMock);
      this.cosseguroMock.UniqueId = this.proposta.UniqueId;
      this.cosseguroMock.CompanyId = this.proposta.CompanyId;
      this.cosseguroMock.ProposalId = this.proposta.Id;
      this.cosseguroMock.CoInsuranceType = this.tipoCosseguro;
    },

    verificarCosseguradoraDuplicada() {
      const cnpjsVistos = new Set();
      for (const cosseguradora of this.listaCosseguradoras) {
        const cnpj = cosseguradora.CoInsurance.Person.CpfCnpj;
        if (cnpjsVistos.has(cnpj)) {
          return true;
        }
        cnpjsVistos.add(cnpj);
      }
      return false;
    },

    adcCosseguradora() {
      this.resetCosseguradoraMock();
      this.listaCosseguradoras.push(Utils.deepClone(this.cosseguroMock));
    },

    removerCosseguradora(index) {
      this.listaCosseguradoras.splice(index, 1);
    },

    removerMascaraCpfCnpj(cpfCnpj) {
      return Utils.removeSpecialChars(cpfCnpj);
    },

    inserirCosseguradoraPadrao(index){
      this.listaCosseguradoras[index].CoInsurance.Person.CpfCnpj = this.userInfo ? this.userInfo.InsuranceCNPJ : "";
      this.listaCosseguradoras[index].CoInsurance.Person.Name = this.userInfo ? this.userInfo.InsuranceName : "";
    },

    async buscarCosseguradora(event, index) {
      if (!Utils.isValidCnpj(event.target.value)) {
        this.$onpoint.errorModal("O CNPJ informado não é válido!");
        return;
      }

      if (this.verificarCosseguradoraDuplicada()) {
        this.$onpoint.errorModal("Cosseguradora já se encontrada cadastrada nesta proposta!");
        this.listaCosseguradoras[index].CoInsurance.Person.CpfCnpj = "";
        return;
      }

      let cnpj = this.removerMascaraCpfCnpj(event.target.value);
      await this.$onpoint.loading(async () => {
        return await mapfreProvider.buscarCosseguradora(cnpj)
          .then(response => {
            this.listaCosseguradoras[index].CoInsurance.Person.CpfCnpj = response.Data ? response.Data[0].CpfCnpj : "";
            this.listaCosseguradoras[index].CoInsurance.Person.Name = response.Data ? response.Data[0].Name : "";
            this.listaCosseguradoras[index].CoInsurance.PersonId = response.Data ? response.Data[0].PersonId : "";
            this.enviarInformacoes();
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },

    trocarTipoCosseguro() {
      this.listaCosseguradoras = [];

      if (this.tipoCosseguro === 0) {
        this.listaCosseguradoras = [];
      }

      if (this.tipoCosseguro === 1) {
        if (this.proposta.CoInsurancers.length > 0 && this.proposta.CoInsurancers[0].CoInsuranceType == this.tipoCosseguro) {
          this.listaCosseguradoras = Utils.deepClone(this.proposta.CoInsurancers);
        }
        if (this.listaCosseguradoras.length === 0) {
          this.adcCosseguradora();
          this.inserirCosseguradoraPadrao(0)
        }
      }

      if (this.tipoCosseguro === 2) {
        if (this.proposta.CoInsurancers.length > 0 && this.proposta.CoInsurancers[0].CoInsuranceType == this.tipoCosseguro) {
          this.listaCosseguradoras = Utils.deepClone(this.proposta.CoInsurancers);
        } else {
          this.adcCosseguradora();
        }
      }

      this.enviarInformacoes();
    },

    enviarInformacoes() {
      this.tipoCosseguro === 0 ? (this.CoInsurancers = null) : (this.CoInsurancers = this.listaCosseguradoras);
      this.$emit("tipoCosseguroMapfre", this.tipoCosseguro);
      this.$emit("cosseguroMapfre", this.CoInsurancers);
    },

    init() {
      this.tipoCosseguro = this.proposta.CoInsurancers.length > 0 ? this.proposta.CoInsurancers[0].CoInsuranceType : 0;
      if (this.tipoCosseguro !== 0) this.listaCosseguradoras = Utils.deepClone(this.proposta.CoInsurancers);
      if (this.listaCosseguradoras.length === 0) this.adcCosseguradora();
      this.enviarInformacoes();
    }
  },
  mounted() {
    this.init();    
  }
};
</script>

<style lang="scss" scoped>
.box-cosseguro {
  background: #f8f8f8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 20px 0px;
  padding: 10px;
  &-cosseguradora {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.footer-right {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}
.trash-icon-mapfre {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(var(--vs-primary), 1) !important;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  margin-left: 2rem;
  cursor: pointer;
}
.adicionar-cosseguradora {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: right;
    color: rgba(var(--vs-primary), 1) !important;
    margin: 0 5px 0 0;
  }

  &-plus-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: rgba(var(--vs-primary), 1) !important;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
  }
}
.icon-font::before {
  display: inline-block;
  position: relative;
  width: 16px;
  font-size: 16px !important;
}
</style>
